.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  background-image: url("./assets/images/stub.png") ;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
}

header {
  backdrop-filter: blur(5px) brightness(120%);
  padding: 15px 0;
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 40px;
  font-family: 'Arimo', sans-serif;
  @media (max-width: 410px) {
    font-size: 18px;
  }
}

a {
  text-decoration: none;
  color: white;
}

footer {
  backdrop-filter: blur(5px) brightness(120%);
  /*padding: 15px;*/
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 40px;
  padding: 40px 0;
}

p {
  text-transform: uppercase;
  font-family: 'Average', sans-serif;
  font-size: 60px;
  margin: 0;
  @media (max-width: 744px) {
    font-size: 50px;

  }
  @media (max-width: 410px) {
    font-size: 24px;

  }
}

.mail {
  text-transform: lowercase;
  font-family: 'Arimo', sans-serif;
  font-size: 40px;
  margin: 0;
  cursor: pointer;
  @media (max-width: 410px) {
    font-size: 18px;

  }
}